.cityEndSection {
  height: 500px;
  z-index: -100;
  overflow: hidden;
}

.closing-city {
  opacity: 40%;
  z-index: 500;
  transform-origin: center;
}

/* Desktop */
@media only screen and (min-width: 1600px) {
  .closing-city {
    transform: scale(0.8);
  }
}

/* Laptop */
@media only screen and (max-width: 1600px) {
  .closing-city {
    transform: scale(0.8);
  }
}

/* Tablet Landscape */
@media only screen and (max-width: 1024px) {
}

/* Tablet portrait */
@media only screen and (max-width: 768px) {
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .closing-city {
    transform: scale(0.4);
  }
}
