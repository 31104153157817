.bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  z-index: -100;
}

.bubbleSmall {
  position: absolute;
  bottom: 5%;
  left: 27%;
}

.bubbleMedium {
  position: absolute;
  top: 5%;
  left: 5%;
}

.bubbleBig {
  position: absolute;
  bottom: -35%;
  right: 1%;
}
