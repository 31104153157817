.citySection {
  height: 100vh;
  overflow-x: hidden;
}

.moving-city {
  z-index: 500;
}

.tag span {
  color: #ffffff !important;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 1.99px;
  line-height: 44px;
  text-transform: uppercase;
}

.tag {
  height: 70px;
  width: 340px;
  background-repeat: no-repeat;
  opacity: 0;
}

.leftTag {
  background-image: url('../../../assets/images/leftTagCity.svg');
  position: absolute;
}

.leftTag span {
  margin-left: 32px;
}

.rightTag {
  background-image: url('../../../assets/images/rightTagCity.svg');
  background-position: right;
  position: absolute;
}

.rightTag span {
  margin-right: 32px;
}

/* Desktop */
@media only screen and (min-width: 1600px) {
  .moving-city {
    transform: scale(0.8);
    height: 100%;
    width: 70%;
  }

  .leftTag {
    left: 7%;
    top: 60%;
  }

  .rightTag {
    right: 12%;
    top: 40%;
  }
}

/* Laptop */
@media only screen and (max-width: 1600px) {
  .moving-city {
    transform: scale(0.8);
    height: 100%;
    width: 70%;
  }

  .leftTag {
    left: 3%;
    top: 60%;
  }

  .rightTag {
    right: 8%;
    top: 40%;
  }
}

/* Tablet Landscape */
@media only screen and (max-width: 1024px) {
  .moving-city {
    transform: scale(0.7);
    height: auto;
    width: auto;
  }

  .leftTag {
    left: 1%;
    top: 65%;
  }

  .rightTag {
    right: 5%;
    top: 35%;
  }
}

/* Tablet portrait */
@media only screen and (max-width: 768px) {
  .moving-city {
    transform: scale(0.7);
    height: auto;
    width: auto;
  }

  .leftTag {
    left: 15%;
    top: 85%;
  }

  .rightTag {
    right: 15%;
    top: 85%;
  }
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .moving-city {
    transform: scale(0.4);
    height: auto;
    width: auto;
  }

  .leftTag {
    left: 45%;
    top: 18%;
  }

  .rightTag {
    right: 45%;
    top: 80%;
  }
}
