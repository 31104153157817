.GSTextContainer {
  z-index: 3;
}

.GSSection {
  padding-right: 0 !important;
  overflow-x: hidden;
  overflow-y: hidden;
}

.car img {
  transform: scale(0.8);
  transform-origin: right;
}

.GSTitle {
  color: #ffffff;
  font-family: MontserratBold;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 64px;
}

.GSText {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 38px;
}

.GSTag {
  opacity: 0;
  z-index: 200;
  position: absolute;
  border-style: solid;
  border-width: 0 0 1px 0;
  padding-bottom: 5px;
  margin-bottom: 20px;
  width: 193px;
  border-image: linear-gradient(90deg, #ffffff 0%, rgba(130, 205, 244, 0.79) 66.28%, rgba(66, 179, 238, 0) 100%) 1;
}

.GSTag span {
  position: relative;
  opacity: 0.8;
  color: #dbc8ff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 2.12px;
  line-height: 44px;
  text-transform: uppercase;
}

.GSResTag {
  border-style: solid;
  border-width: 0 0 1px 0;
  padding-bottom: 5px;
  margin-bottom: 20px;
  width: 193px;
  border-color: white;
  margin-bottom: 50px;
}

.GSResTag span {
  color: #dbc8ff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 2.12px;
  line-height: 44px;
  text-transform: uppercase;
  white-space: nowrap;
}

.GSTag1 {
  top: 18%;
  right: 55%;
}

.GSTag1 span {
  top: 0;
  left: -75px;
}

.GSTag2 {
  top: 52%;
  right: 71%;
}

.GSTag3 {
  top: 70%;
  right: 60%;
}

.GSTag3 span {
  top: 0;
  left: -75px;
}

.GSTag4 {
  top: 35%;
  right: 70%;
}

.GSTag4 span {
  white-space: nowrap;
  top: 0;
  left: -75px;
}

/* Desktop */
@media only screen and (min-width: 1600px) {
  .car {
    width: 50%;
    transform: translateX(100%);
  }

  .GSTextContainer {
    width: 50%;
  }

  .GSContainer {
    flex-direction: row;
  }

  .resTag {
    display: none;
  }

  .GSTextContainer {
    align-items: flex-start;
  }
}

/* Laptop */
@media only screen and (max-width: 1600px) {
  .car {
    width: 50%;
    transform: translateX(100%);
  }

  .GSTextContainer {
    width: 50%;
  }

  .GSContainer {
    flex-direction: row;
  }

  .resTag {
    display: none;
  }

  .GSTextContainer {
    align-items: flex-start;
  }
}

/* Tablet Landscape */
@media only screen and (max-width: 1024px) {
}

/* Tablet portrait */
@media only screen and (max-width: 768px) {
  .car {
    width: 100%;
    transform: translateX(0px);
  }

  .GSContainer {
    flex-direction: column;
  }

  .GSTextContainer {
    width: 100%;
  }

  .GSTitle,
  .GSText {
    text-align: center;
  }

  .resTag {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .GSTextContainer {
    align-items: center;
  }
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .car {
    width: 100%;
    transform: translateX(0px);
  }

  .GSContainer {
    flex-direction: column;
  }

  .GSTextContainer {
    width: 100%;
  }

  .GSTitle,
  .GSText {
    text-align: center;
  }

  .resTag {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .GSTextContainer {
    align-items: flex-start;
    padding-right: 5%;
  }
}
