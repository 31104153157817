span {
  font-weight: bold;
}

.experienceSection {
  overflow: hidden;
  margin-top: 200px;
}

.experienceTitle {
  color: #ffffff;
  font-family: MontserratItalic;
  font-style: italic;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 1.06px;
  line-height: 64px;
  text-align: center;
}

.experienceText {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.56px;
  line-height: 39px;
  text-align: center;
  margin-top: 55px !important;
  margin-bottom: 0;
}

.experienceText strong {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 20px;
  letter-spacing: 0.56px;
  line-height: 27px;
  text-align: center;
}

/* Desktop */
@media only screen and (min-width: 1600px) {
}

/* Laptop */
@media only screen and (max-width: 1600px) {
}

/* Tablet Landscape */
@media only screen and (max-width: 1024px) {
}

/* Tablet portrait */
@media only screen and (max-width: 768px) {
}

/* Mobile */
@media only screen and (max-width: 480px) {
}
