.backLightSection {
  overflow: hidden;
}

.tagBackLight {
  height: 70px;
  width: 340px;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 500;
}

.tagBackLight span {
  color: #ffffff !important;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 1.99px;
  line-height: 44px;
  text-transform: uppercase;
}

.leftTagBackLight {
  background-image: url('../../../assets/images/leftTagBackLight.svg');
  position: absolute;
}

.fitInBox {
  margin-left: 50px !important;
}

.leftTagBackLight span {
  margin-left: 32px;
}

.rightTagBackLight {
  background-image: url('../../../assets/images/rightTagBackLight.svg');
  background-position: right;
  position: absolute;
}

.rightTagBackLight span {
  margin-right: 32px;
}

.backLightSection {
  margin-top: 150px;
}

/* Desktop */
@media only screen and (min-width: 1600px) {
  .back-light {
    transform: scale(0.7);
  }

  .leftTagBackLight {
    left: 15%;
    top: 45%;
  }

  .rightTagBackLight {
    right: 10%;
    top: 58%;
  }
}

/* Laptop */
@media only screen and (max-width: 1600px) {
  .back-light {
    transform: scale(0.7);
  }

  .leftTagBackLight {
    left: 10%;
    top: 40%;
  }

  .rightTagBackLight {
    right: 10%;
    top: 58%;
  }
}

/* Tablet Landscape */
@media only screen and (max-width: 1024px) {
  .back-light {
    transform: scale(0.7);
  }

  .leftTagBackLight {
    left: 2%;
    top: 38%;
  }

  .rightTagBackLight {
    right: 7%;
    top: 60%;
  }
}

/* Tablet portrait */
@media only screen and (max-width: 768px) {
  .back-light {
    transform: scale(0.6);
  }

  .leftTagBackLight {
    left: 5%;
    top: 26%;
  }

  .rightTagBackLight {
    right: 5%;
    top: 70%;
  }
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .back-light {
    transform: scale(0.4);
  }

  .leftTagBackLight {
    left: 20%;
    top: 17%;
  }

  .rightTagBackLight {
    right: 50%;
    top: 80%;
  }
}
