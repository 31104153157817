.solutionTextContainer {
  margin-right: 20%;
}

.solutionTitle {
  color: #ffffff;
  font-family: MontserratBold;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 74px;
  text-align: center;
}

.solutionText {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.56px;
  line-height: 44px;
  text-align: center;
}

.solutionTextBlock {
  opacity: 0;
}
