.rgpd {
  position: fixed;
  background: linear-gradient(
    90deg,
    rgba(48, 27, 63, 0.5) 0%,
    rgba(5, 8, 44, 0.9) 18.3%,
    rgba(4, 5, 21, 0.9) 47.12%,
    rgba(6, 10, 22, 0.9) 100%
  );
  backdrop-filter: blur(2px);
  border-radius: 10px;
  bottom: 0;
  left: 0;
  padding: 30px;
  flex-direction: column;
}

.rgpd p {
  font-family: Rubik, sans-serif;
  color: white;
  font-size: 13px;
  width: 100%;
}

.rgpd button {
  margin: 0;
}

/* Desktop */
@media only screen and (min-width: 1600px) {
  .rgpd {
    width: 25%;
  }
}

/* Laptop */
@media only screen and (max-width: 1600px) {
  .rgpd {
    width: 25%;
  }
}

/* Tablet Landscape */
@media only screen and (max-width: 1024px) {
  .rgpd {
    width: 100%;
  }
}

/* Tablet portrait */
@media only screen and (max-width: 768px) {
}

/* Mobile */
@media only screen and (max-width: 480px) {
}
