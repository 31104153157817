.frontLightSection {
  overflow: hidden;
}

.tagFrontLight {
  height: 70px;
  width: 340px;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 500;
}

.tagFrontLight span {
  color: #ffffff !important;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 1.99px;
  line-height: 44px;
  text-transform: uppercase;
}

.leftTagFrontLight {
  background-image: url('../../../assets/images/leftTagFrontLight.svg');
  position: absolute;
}

.leftTagFrontLight span {
  margin-left: 32px;
}

.rightTagFrontLight {
  background-image: url('../../../assets/images/rightTagFrontLight.svg');
  background-position: right;
  position: absolute;
}

.rightTagFrontLight span {
  margin-right: 32px;
}

.frontLightSection {
  margin-top: 150px;
}

/* Desktop */
@media only screen and (min-width: 1600px) {
  .front-light {
    transform: scale(0.7);
  }

  .leftTagFrontLight {
    left: 20%;
    top: 30%;
  }

  .rightTagFrontLight {
    right: 15%;
    top: 58%;
  }
}

/* Laptop */
@media only screen and (max-width: 1600px) {
  .front-light {
    transform: scale(0.7);
  }
  .leftTagFrontLight {
    left: 18%;
    top: 25%;
  }

  .rightTagFrontLight {
    right: 10%;
    top: 60%;
  }
}

/* Tablet Landscape */
@media only screen and (max-width: 1024px) {
  .leftTagFrontLight {
    left: 15%;
    top: 18%;
  }

  .rightTagFrontLight {
    right: 5%;
    top: 72%;
  }
}

/* Tablet portrait */
@media only screen and (max-width: 768px) {
  .leftTagFrontLight {
    left: 5%;
    top: 18%;
  }

  .rightTagFrontLight {
    right: 5%;
    top: 75%;
  }
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .front-light {
    transform: scale(0.5);
  }

  .leftTagFrontLight {
    left: 25%;
    top: 18%;
  }

  .rightTagFrontLight {
    right: 15%;
    top: 80%;
  }
}
