.subtitle {
  margin-bottom: 0;
  margin-left: 10px;
}

video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

h1 {
  color: #ffffff;
  font-family: MontserratBold;
  font-size: 120px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 125px;
  text-shadow: 4px 0 23px rgba(255, 26, 218, 0.46), -2px 0 10px rgba(58, 213, 255, 0.67);
}

.introP {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 38px;
  margin-top: 10px;
  margin-bottom: 0;
}

.maxSize {
  max-height: 100vh !important;
  overflow: hidden;
}

.noMinSize {
  min-height: 0;
}

.startBox {
  background-image: url('../../../assets/images/start.png');
  background-size: cover;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  height: 105px;
  width: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  cursor: pointer;
}

.start {
  color: white;
  text-transform: uppercase;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 2.91px;
  line-height: 36px;
  text-align: center;
}

/* Desktop */
@media only screen and (min-width: 1600px) {
}

/* Laptop */
@media only screen and (max-width: 1600px) {
}

/* Tablet Landscape */
@media only screen and (max-width: 1024px) {
}

/* Tablet portrait */
@media only screen and (max-width: 768px) {
  .introSection {
    display: none;
  }

  #mainTitle {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .introSection {
    display: none;
  }

  #mainTitle {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
