@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratBold';
  src: url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratThin';
  src: url('../fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
  font-weight: thin;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratMedium';
  src: url('../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'MontserratItalic';
  src: url('../fonts/Montserrat/Montserrat-Italic.ttf') format('truetype');
  font-style: italic;
}

section {
  min-height: 100vh;
  width: 100%;
}

section > .parentSize {
  min-height: 100vh;
}

.backgroundPlaceholder {
  background-color: #e6e6e6;
}

html {
  background: linear-gradient(134.93deg, #301b3f 0%, #05082c 18.3%, #040515 47.12%, #060a16 100%);
  background-repeat: no-repeat;
  background-clip: content-box;
  background-attachment: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
body {
  background-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  overflow-y: auto !important;
}

.textCenter {
  text-align: center;
}

html::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.scroller {
  height: 100vh;
}

.scrollbar-track-y {
  visibility: hidden;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .notFullScreen {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .notFullScreen {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .notFullScreen {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
}

/* Large devices (laptops/desktops, 1050px and up) */
@media only screen and (min-width: 1050px) {
  .notFullScreen {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1450px) {
  .notFullScreen {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
}
