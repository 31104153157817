.vehiclesSection {
  overflow: hidden;
  position: relative;
}

.vehiclesTextBox {
  z-index: 100;
}

.vehiclesText {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 38px;
}

.hour {
  opacity: 0.5;
  color: #ba99f8;
  font-family: MontserratThin;
  font-size: 150px;
  font-weight: 100;
  letter-spacing: 1.88px;
  line-height: 63px;
  text-align: right;
}

.hour2 {
  opacity: 0.5;
  color: #ba99f8;
  font-family: MontserratThin;
  font-size: 78px;
  font-weight: lighter;
  letter-spacing: 0.97px;
  line-height: 63px;
  text-align: right;
  margin-top: 20px;
}

.v-box1 {
  width: 50%;
}

.v-box3 {
  width: 50%;
}

.scooter {
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
}

.motorBikeSection {
  margin-top: 50vh;
  height: 100vh;
  overflow-y: hidden;
}

.motorBike {
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  transform: scale(0.8);
}

.motorBikeContainer {
  position: relative;
  transform: translateY(-100%);
}

.scooterPath {
  position: absolute;
  top: 100px;
  left: -15%;
}

.motorBikePath {
  position: absolute;
  top: 100px;
  left: 7%;
}

.time1,
.time2 {
  position: relative;
}

/* Desktop */
@media only screen and (min-width: 1600px) {
  .vehiclesTitle {
    color: #ffffff;
    font-family: MontserratBold;
    font-size: 38px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 64px;
  }

  .v-box1 {
    align-items: center;
    display: flex;
  }
  .v-box3 {
    align-items: center;
    display: flex;
  }
  .v-box2 {
    align-items: center;
    width: 50%;
  }

  .vehiclesSection {
    height: 100vh;
  }

  .motorBikeRes {
    display: none;
  }

  .time1,
  .time2 {
    display: flex;
  }
}

/* Laptop */
@media only screen and (max-width: 1600px) {
  .vehiclesTitle {
    color: #ffffff;
    font-family: MontserratBold;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 64px;
  }

  .v-box1 {
    align-items: center;
    display: flex;
  }
  .v-box3 {
    align-items: flex-start;
    display: flex;
  }
  .v-box2 {
    align-items: flex-end;
    width: 50%;
  }

  .vehiclesSection {
    height: 100vh;
  }
  .motorBikeRes {
    display: none;
  }

  .time1,
  .time2 {
    display: flex;
  }
}

/* Tablet Landscape */
@media only screen and (max-width: 1024px) {
  .vehiclesTitle {
    color: #ffffff;
    font-family: MontserratBold;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 64px;
  }

  .v-box3 {
    align-items: flex-start;
    display: flex;
  }
  .v-box2 {
    align-items: flex-end;
  }
}

/* Tablet portrait */
@media only screen and (max-width: 768px) {
  .vehiclesTitle {
    color: #ffffff;
    font-family: MontserratBold;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 64px;
  }

  .v-box3 {
    align-items: flex-start;
    display: flex;
  }
  .v-box2 {
    align-items: flex-end;
  }

  .time1,
  .time2 {
    display: none;
  }
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .vehiclesTitle {
    color: #ffffff;
    font-family: MontserratBold;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 64px;
  }
  .v-box1 {
    display: flex;
    width: 100%;
    margin-top: 100px;
    align-items: flex-start;
  }
  .v-box3 {
    align-items: flex-start;
    display: none;
  }
  .v-box2 {
    align-items: flex-end;
    width: 100%;
    margin-top: 100px;
  }

  .scooter,
  .motorBike {
    display: none;
  }

  .vehiclesTitle,
  .vehiclesText {
    text-align: center;
  }

  .vehiclesContainer {
    flex-direction: column;
  }

  .vehiclesSection {
    height: auto;
  }

  .motorBikeRes {
    display: flex;
    margin-top: 100px;
  }
  .time1,
  .time2 {
    display: flex;
  }
}
