.easyTextContainer {
  z-index: 3;
  height: 100%;
}

.easyCar {
  position: relative;
  height: 100%;
}

.easySection {
  height: 100vh;
  overflow: hidden;
}

.easyTitle {
  color: #ffffff;
  font-family: MontserratBold;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 64px;
}

.easyText {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 38px;
}

.carPark {
  position: relative;
  transform: scale(1);
  transform-origin: top;
}

/* Desktop */
@media only screen and (min-width: 1600px) {
  .easyCar {
    display: flex;
    width: 50%;
  }
  .easyTextContainer {
    width: 50%;
    align-items: start;
  }
}

/* Laptop */
@media only screen and (max-width: 1600px) {
  .easyCar {
    display: flex;
    width: 50%;
  }
  .easyTextContainer {
    width: 50%;
    align-items: start;
  }
}

/* Tablet Landscape */
@media only screen and (max-width: 1024px) {
}

/* Tablet portrait */
@media only screen and (max-width: 768px) {
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .easyCar {
    display: none;
  }

  .easyTextContainer {
    width: 100%;
    align-items: center;
  }

  .easyTitle,
  .easyText {
    text-align: center;
  }
}
