.terms {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  padding: 5% 10% 10% 10%;
  background: linear-gradient(134.93deg, #301b3f 0%, #05082c 18.3%, #040515 47.12%, #060a16 100%);
  overflow: auto;
}

.cross {
  color: white;
  cursor: pointer;
}

.termsText {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.54px;
}

.termSubtitle {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.54px;
  line-height: 39px;
}
