.podImagePlaceholder2 {
  margin-top: 60px;
  margin-bottom: 60px;
  height: 500px !important;
}

.podTextContainer {
  z-index: 3;
}

.phone {
  height: 826px;
  width: 321px;
}

.podTitle {
  color: #ffffff;
  font-family: MontserratBold;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 64px;
}

.podText {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 38px;
}

/* Desktop */
@media only screen and (min-width: 1600px) {
  .podPhoneContainer {
    display: flex;
    width: 50%;
  }

  .podTextContainer {
    width: 50%;
  }

  .podContainer {
    flex-direction: row;
  }

  .phone {
    transform: translateY(700px);
  }
}

/* Laptop */
@media only screen and (max-width: 1600px) {
  .podPhoneContainer {
    display: flex;
    width: 50%;
  }

  .podTextContainer {
    width: 50%;
  }

  .podContainer {
    flex-direction: row;
  }

  .phone {
    transform: translateY(700px);
  }
}

/* Tablet Landscape */
@media only screen and (max-width: 1024px) {
}

/* Tablet portrait */
@media only screen and (max-width: 768px) {
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .podTextContainer {
    width: 100%;
  }

  .podPhoneContainer {
    display: flex;
    width: 100%;
  }

  .podTitle,
  .podText {
    text-align: center;
  }

  .podContainer {
    flex-direction: column-reverse;
  }

  .phone {
    transform: translateY(0px);
    transform: scale(0.8);
  }
}
