.carSection {
  overflow-y: hidden;
  overflow-x: hidden;
}

.carVehiclesContainer {
  position: relative;
}
.carVehicles {
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
}

.hourCar {
  opacity: 0.5;
  color: #ba99f8;
  font-family: MontserratThin;

  font-weight: 100;
  letter-spacing: 1.88px;
  line-height: 63px;
  text-align: right;
}

.carPath {
  position: absolute;
  top: 100px;
  left: 20%;
}

.hourCar2 {
  opacity: 0.5;
  color: #ba99f8;
  font-family: MontserratThin;
  font-weight: lighter;
  letter-spacing: 0.97px;
  line-height: 63px;
  text-align: right;
  margin-top: 20px;
}

.time3 {
  margin-bottom: 100px;
  z-index: 100;
  position: relative;
}

/* Desktop */
@media only screen and (min-width: 1600px) {
  .v-box5 {
    align-items: flex-start;
    margin-left: 9%;
    display: flex;
    width: 50%;
  }
  .v-box6 {
    align-items: center;
    width: 50%;
  }
  .carVehicles {
    transform: scale(0.9);
  }

  .hourCar {
    font-size: 150px;
  }
  .hourCar2 {
    font-size: 78px;
  }

  .carSection {
    margin-top: 2500px;
  }
  .carVehiclesRes {
    display: none;
  }

  .carVehiclesContainer {
    flex-direction: row;
  }

  .carSection {
    height: 100vh;
  }
  .time3 {
    display: flex;
  }
}

/* Laptop */
@media only screen and (max-width: 1600px) {
  .v-box5 {
    align-items: flex-start;
    display: flex;
    width: 50%;
  }
  .v-box6 {
    align-items: flex-end;
    width: 50%;
  }
  .hourCar {
    font-size: 150px;
  }
  .hourCar2 {
    font-size: 78px;
  }

  .carVehicles {
    transform: scale(0.9);
  }
  .carSection {
    margin-top: 2500px;
  }
  .carVehiclesRes {
    display: none;
  }

  .carVehiclesContainer {
    flex-direction: row;
  }

  .carSection {
    height: 100vh;
  }
  .time3 {
    display: flex;
  }
}

/* Tablet Landscape */
@media only screen and (max-width: 1024px) {
  .v-box5 {
    align-items: flex-start;
  }
  .v-box6 {
    align-items: flex-end;
  }
  .hourCar {
    font-size: 100px;
  }
  .hourCar2 {
    font-size: 50px;
  }
}

/* Tablet portrait */
@media only screen and (max-width: 768px) {
  .v-box5 {
    align-items: flex-start;
  }
  .v-box6 {
    align-items: flex-end;
  }

  .carVehicles {
    transform: scale(0.7);
  }
  .hourCar {
    font-size: 100px;
  }
  .hourCar2 {
    font-size: 50px;
  }

  .time3 {
    display: none;
  }
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .v-box5 {
    display: none;
  }
  .v-box6 {
    align-items: flex-end;
    width: 100%;
    margin-top: 100px;
  }

  .hourCar {
    font-size: 100px;
  }
  .hourCar2 {
    font-size: 50px;
  }
  .carSection {
    margin-top: 0px;
  }
  .carVehicles {
    display: none;
  }

  .carVehiclesRes {
    display: flex;
  }

  .carVehiclesContainer {
    flex-direction: column;
  }

  .carVehiclesResImg {
    margin-top: 100px;
  }

  .carSection {
    height: auto;
  }
}
