header > span {
  font-size: xx-large;
}

header {
  padding-top: 30px;

  position: fixed;
  z-index: 1000;
  width: 100%;
  background-color: #0000005e;
  box-shadow: 0px 16px 69px 48px #0000005e;
}

header button {
  color: #ffffff !important;
  font-family: Montserrat;
  font-size: 12px !important;
  letter-spacing: 5px !important;
  line-height: 47px !important;
  text-align: right;
  text-transform: uppercase;
}
