footer {
  text-align: center;
  margin-bottom: 15px;
  opacity: 0.8;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 38px;
  text-align: center;
}

footer strong {
  font-family: MontserratMedium;
  cursor: pointer;
}
