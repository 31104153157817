.contact {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  padding: 5% 10% 10% 10%;
  overflow: auto;
}

.btn:focus {
  box-shadow: none !important;
}

.overlay {
  height: 100%;
  width: 100%;

  backdrop-filter: blur(2px);

  background: linear-gradient(
    90deg,
    rgba(48, 27, 63, 0.5) 0%,
    rgba(5, 8, 44, 0.9) 18.3%,
    rgba(4, 5, 21, 0.9) 47.12%,
    rgba(6, 10, 22, 0.9) 100%
  );
}

.cross {
  color: white;
  cursor: pointer;
}

.captchaTerms {
  margin-top: 10px;
  font-size: x-small;
}

.contactText {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.54px;
  line-height: 39px;
}

.contactTitle {
  color: #ffffff;
  font-family: MontserratBold;
  font-size: 80px;
  font-weight: bold;
  letter-spacing: 1.33px;
  line-height: 125px;
  text-shadow: 4px 0 23px rgba(255, 26, 218, 0.46), -2px 0 10px rgba(58, 213, 255, 0.67);
}

.contactAfterSalesText {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 20px;
  letter-spacing: 0.54px;
}

.contactAfterSalesTitle {
  color: #ffffff;
  font-family: MontserratBold;
  font-weight: bold;
  letter-spacing: 1.33px;
  margin-top: 30px;
 }

.MuiFormLabel-root {
  color: white !important;
  text-transform: uppercase;
  font-family: Montserrat !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 17px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
}

.MuiInput-underline:after,
.MuiInput-underline:before {
  border-color: white !important;
}

.MuiInput-underline {
  color: white !important;
}

.MuiInputBase-input {
  padding-bottom: 20px !important;
}

.inputMargin {
  margin-left: 20px !important;
}

.errorMsg {
  color: #c95f5f;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 17px;
}

.successMsg {
  color: #63c95f;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 17px;
}

/* Desktop */
@media only screen and (min-width: 1600px) {
  .contactContainer {
    flex-direction: row;
  }

  .contactTextContainer,
  .contactFormContainer {
    width: 50%;
  }
}

/* Laptop */
@media only screen and (max-width: 1600px) {
  .contactContainer {
    flex-direction: row;
  }

  .contactTextContainer,
  .contactFormContainer {
    width: 50%;
  }
}

/* Tablet Landscape */
@media only screen and (max-width: 1024px) {
  .contactContainer {
    flex-direction: column;
  }

  .contactTextContainer,
  .contactFormContainer {
    width: 100%;
  }

  .contactText,
  .contactTitle {
    text-align: center;
  }

  .contactAfterSalesText,
  .contactAfterSalesTitle  {
    text-align: center;
  }
}

/* Tablet portrait */
@media only screen and (max-width: 768px) {
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .contactTitle {
    font-size: 65px;
  }
  
  .contactAfterSalesTitle {
    font-size: 65px;
  }
}
