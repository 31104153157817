.outroTextContainer {
  text-align: center;
}
.outroSection {
  z-index: 700;
  margin-top: 2700px;
}
.outroTitle {
  color: #ffffff;
  font-family: MontserratBold;
  font-size: 84px;
  font-weight: bold;
  letter-spacing: 1.4px;
  line-height: 125px;
  text-align: center;
  text-shadow: 4px 0 23px rgba(255, 26, 218, 0.46), -2px 0 10px rgba(58, 213, 255, 0.67);
  margin-bottom: 0;
}

.outroSubText {
  opacity: 0;
}

.outroText {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 38px;
  text-align: center;
}

.btn-outro {
  border-radius: 17px;
  background-color: #c7eef9;
  box-shadow: 0 0 36px 0 rgba(68, 204, 226, 0.65);
  color: #050816;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 38px;
  text-transform: uppercase;
  width: 35%;
  margin-top: 42px;
  padding: 0;
}
